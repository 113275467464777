.adminUsers {
  width: 100%;
  min-width: 750px; }
  .adminUsers .admin-title {
    font-size: 18pt;
    font-weight: bold;
    margin: 25px 0 25px -50px;
    text-align: center; }
  .adminUsers .user-list {
    margin: 10px;
    width: 250px;
    font-size: 14pt;
    font-family: 'Roboto', sans-serif; }
  .adminUsers .admin-arrow {
    font-size: 32pt;
    cursor: pointer; }
  .adminUsers .admin-controls {
    display: table;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto; }
    .adminUsers .admin-controls .block {
      display: table-cell;
      vertical-align: middle;
      width: 250px; }
      .adminUsers .admin-controls .block label {
        font-size: 14pt;
        font-weight: bold;
        margin-left: 10px;
        margin-bottom: -15px; }
    .adminUsers .admin-controls .blockButtons {
      display: table-cell;
      vertical-align: middle;
      width: 75px;
      text-align: center;
      position: relative;
      top: -20px; }
    .adminUsers .admin-controls .blockTop {
      display: table-cell;
      vertical-align: top;
      padding-top: 40px;
      padding-left: 25px;
      text-align: center;
      width: 75px; }
    .adminUsers .admin-controls .admin-button-container {
      width: 100%;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center; }
      .adminUsers .admin-controls .admin-button-container .adminSubmit {
        font-size: 14pt;
        padding: 15px 10px;
        width: 125px; }
