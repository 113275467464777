.Header {
  color: black;
  height: 75px;
  margin: 0;
  background-color: #ffffff;
  border: none;
  /* Small Devices, Tablets */ }
  .Header .logoContainer {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center; }
    .Header .logoContainer .navbar-brand {
      padding: 0;
      padding-left: 45px; }
      .Header .logoContainer .navbar-brand .logo-image {
        max-width: 132px;
        margin-top: -10px;
        margin-left: -45px; }
  .Header .HelpLinkContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px; }
    .Header .HelpLinkContainer .HelpImage .logo-image {
      max-width: 132px;
      max-height: 45px;
      margin-top: 15px;
      margin-right: 15px; }
  .Header .LogInNameContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    margin-top: 25px; }
    .Header .LogInNameContainer .LoginUserText {
      color: black !important; }
  .Header .SignInButtonContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    margin-top: 10px; }
    .Header .SignInButtonContainer .SignInButton {
      height: 48px;
      width: 150px;
      border-radius: 4px;
      background-color: #243761;
      color: #ffffff;
      font-size: 18px;
      font-weight: 400;
      line-height: 15px; }
  .Header .BackgroundImageSection {
    height: calc(100vh - 150px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../../Assets/Images/ModalBackground.JPG");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% center; }
  .Header .CustomModal {
    height: 487px !important;
    width: 636.08px !important;
    border-radius: 4px;
    background-color: #6fa5a2 !important; }
  .Header .flex-container {
    display: flex;
    flex-wrap: nowrap;
    background-color: #6fa5a2; }
    .Header .flex-container .box {
      width: 50%;
      margin: 10px;
      text-align: center; }
      .Header .flex-container .box .login {
        background-color: #ffffff;
        margin: 50px;
        text-align: center; }
        .Header .flex-container .box .login .label-text {
          font-weight: normal;
          margin: 5px;
          color: #4a4a4a;
          font-family: Roboto, sans-serif;
          font-size: 14px;
          line-height: 16px; }
        .Header .flex-container .box .login .label-header {
          margin-top: 15px;
          border-bottom: solid #c9c8c8 1px; }
        .Header .flex-container .box .login .SignInButton {
          height: 35px;
          width: 100px;
          border-radius: 4px;
          background-color: #243761;
          color: #ffffff;
          font-size: 14px;
          font-weight: 400;
          line-height: 15px;
          margin: 20px; }
    .Header .flex-container .link {
      color: #ffffff !important; }
  .Header .error {
    color: red;
    font-weight: bold; }
  .Header .close {
    position: absolute;
    right: 10px;
    width: 32px;
    height: 32px;
    opacity: 0.3; }
  .Header .close:hover {
    opacity: 1; }
  .Header .close:before,
  .Header .close:after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 33px;
    width: 2px;
    background-color: #333; }
  .Header .close:before {
    transform: rotate(45deg); }
  .Header .close:after {
    transform: rotate(-45deg); }
  .Header .navbar-nav > li > a {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .Header .navbar {
    min-height: 75px !important; }
  @media only screen and (max-width: 768px) {
    .Header .logoContainer {
      float: left; }
    .Header .SignInButtonContainer {
      float: right; } }
