.book-status-main {
  position: fixed;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  user-select: none; }
  .book-status-main h2 {
    text-align: center; }
  .book-status-main #withdrawn label {
    margin: 25px 10px; }
  .book-status-main #withdrawn input {
    margin-right: 10px; }
  .book-status-main #status-note {
    margin-left: 10px;
    width: 435px; }
    .book-status-main #status-note a {
      margin-left: 25px;
      cursor: pointer; }

.BookStatusCustomModal {
  height: 400px !important;
  width: 520px !important;
  border: 3px solid black;
  border-radius: 4px;
  background-color: white;
  padding: 0 20px 20px 20px; }

.fieldGroup {
  margin: 25px 0; }

.fieldGroup select {
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid lightgray; }

.status-type-group {
  margin-left: 15px; }
  .status-type-group label {
    margin-right: 25px;
    font-weight: normal; }
    .status-type-group label input {
      margin-right: 5px; }
