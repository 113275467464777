.book-contributor-main {
  position: fixed;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .book-contributor-main h2 {
    text-align: center; }
  .book-contributor-main #firstName, .book-contributor-main #lastName {
    width: 265px; }

.BookContributorCustomModal {
  height: 350px !important;
  width: 600px !important;
  border: 3px solid black;
  border-radius: 4px;
  background-color: white;
  padding: 0 20px 20px 20px; }

.fieldGroup {
  margin: 10px 0; }
  .fieldGroup .contributor-checkbox-group {
    display: flex;
    flex-direction: row; }
    .fieldGroup .contributor-checkbox-group .check-box {
      margin-left: 15px; }

.fieldGroup select {
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid lightgray; }

.requiredMarker {
  color: red;
  font-weight: normal; }
