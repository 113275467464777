.SearchResultsPageComponent .BackgroundImageSection {
  height: calc(35vh - 75px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../../Assets/Images/EmoryArchive.JPG");
  background-repeat: no-repeat;
  background-size: cover;
  border-top: solid black 2px;
  border-bottom: solid black 2px;
  background-position: 100% center;
  background-attachment: fixed; }

.SearchResultsPageComponent .SearchAndTitleContainer .Search {
  flex: 1; }

.SearchResultsPageComponent .NumberOfResultsSection {
  height: 50px;
  width: 100%;
  background-color: #f1f1f1; }
  .SearchResultsPageComponent .NumberOfResultsSection .NumberOfResultsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; }
  .SearchResultsPageComponent .NumberOfResultsSection .SortByContainer {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-right: 75px; }

.SearchResultsPageComponent .FilterBarSection {
  width: 21% !important;
  min-width: 265px;
  height: 700px;
  border-top: 1px solid #979797;
  border-right: 1px solid #979797;
  border-bottom: 1px solid #979797; }

.SearchResultsPageComponent .SearchResultsSection .SearchResultsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; }

.SearchResultsPageComponent .row {
  height: 100%;
  width: 100%;
  margin: 0; }

.SearchResultsPageComponent .MiddleSection {
  height: 100%; }

.SearchResultsPageComponent .MiddleSectionContent {
  text-align: center;
  width: 86%;
  color: #4a4a4a;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 16px; }

.SearchResultsPageComponent .RightSection {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0%; }

.SearchResultsPageComponent .SortBySelectDropdown {
  font-family: 'Roboto', Helvetica, Arial, verdana, sans-serif;
  margin-left: 15px;
  float: right;
  padding: 0%;
  min-width: 240px; }

.SearchResultsPageComponent #SortBySelectLabel {
  margin-top: 6px; }

.SearchResultsPageComponent .standard-select {
  font-family: 'Roboto', Helvetica, Arial, verdana, sans-serif; }

.SearchResultsPageComponent .PerPageSelect {
  width: 20%;
  float: right; }

.SearchResultsPageComponent .pagination {
  margin-top: 26px;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */ }

.SearchResultsPageComponent .pagination > li > a {
  cursor: pointer; }

.SearchResultsPageComponent .NumberOfResultsContainer .MiddleSectionContent {
  text-align: left; }

.SearchResultsPageComponent .NoWrap {
  white-space: nowrap; }
