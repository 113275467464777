.journal-articles .select-title .modal-link a {
  cursor: pointer;
  user-select: none;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #23527c; }

.journal-articles .select-title .articles-table {
  border: 1px solid darkgray; }

.journal-articles .links-title {
  min-width: 55%;
  max-width: 55% !important; }

.journal-articles .links a {
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #23527c; }

table {
  position: relative;
  border-collapse: collapse;
  width: 100%; }

th {
  background-color: #dedede;
  position: sticky;
  top: 0;
  padding: 5px;
  text-align: center; }

td {
  border: 1px solid darkgray;
  padding: 5px; }

.react-tooltip-lite {
  background: #333;
  color: white; }

.react-tooltip-lite-arrow {
  border-color: #333; }
