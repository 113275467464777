.divTable {
  display: table; }

.divTableRow {
  display: table-row; }

.divTableHeading {
  background-color: #EEE;
  display: table-header-group; }

.divTableCell, .divTableHead {
  border: 1px solid #999999;
  display: table-cell;
  padding: 3px 10px; }

.divTableHeading {
  background-color: #EEE;
  display: table-header-group;
  font-weight: bold; }

.divTableFoot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold; }

.divTableBody {
  display: table-row-group; }
