.book-modal-main {
  position: fixed;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .book-modal-main h2 {
    text-align: center; }
  .book-modal-main .dialogButtons {
    margin-bottom: 35px; }

.CollectedWorksEssaysModal {
  width: 90%;
  height: 90%;
  max-width: 1050px;
  max-height: 90%;
  border: 3px solid black;
  border-radius: 4px;
  background-color: white;
  overflow-y: scroll;
  overflow-x: hidden; }
  .CollectedWorksEssaysModal .fieldGroup {
    margin: 10px 0; }
    .CollectedWorksEssaysModal .fieldGroup label {
      margin-right: 15px; }
      .CollectedWorksEssaysModal .fieldGroup label input {
        margin-right: 5px; }
  .CollectedWorksEssaysModal .fieldGroup select {
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid lightgray; }
  .CollectedWorksEssaysModal #pageStart, .CollectedWorksEssaysModal #pageEnd {
    width: 105px; }
