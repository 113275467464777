.upload-meta {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 10px; }
  .upload-meta .title-section .upload-text-container {
    padding-bottom: 15px;
    width: 100%; }
    .upload-meta .title-section .upload-text-container .upload-text {
      font-size: 42px;
      font-weight: bold;
      line-height: 28px;
      color: #243761;
      margin-left: -20px; }
  .upload-meta .form-control-small {
    height: 30px; }
  .upload-meta .col-form-label {
    margin-top: 4px;
    margin-bottom: 0; }
  .upload-meta .form-check-group {
    margin-top: 4px; }
  .upload-meta .display-only {
    cursor: default; }
  .upload-meta .TextContainer {
    min-height: calc(60vh - 75px);
    display: flex;
    flex-direction: column;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    padding-top: 30px;
    padding-bottom: 50px; }
    .upload-meta .TextContainer .upload-form-entry {
      margin-left: 125px;
      margin-bottom: 150px; }
    .upload-meta .TextContainer .required {
      width: 100%; }
      .upload-meta .TextContainer .required .required-legend {
        margin-left: -10px; }
    .upload-meta .TextContainer #account {
      min-width: 250px; }
    .upload-meta .TextContainer .DetailsSection {
      display: flex;
      justify-content: center;
      align-items: center; }
      .upload-meta .TextContainer .DetailsSection .DetailsContainer {
        width: 95%;
        color: #4a4a4a;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        line-height: 32px; }
        .upload-meta .TextContainer .DetailsSection .DetailsContainer #sub-title {
          margin-top: 10px; }
    .upload-meta .TextContainer .row {
      margin: 0;
      margin-bottom: 20px;
      color: #4a4a4a;
      font-family: "Roboto", sans-serif;
      font-size: 15px; }
    .upload-meta .TextContainer .row:first-of-type {
      margin-top: 10px; }
    .upload-meta .TextContainer .select-title {
      margin-left: 15px;
      top: 10px; }
      .upload-meta .TextContainer .select-title .select-title-text {
        position: relative;
        top: 12px; }
    .upload-meta .TextContainer .select-list {
      margin-top: -5px;
      margin-bottom: 25px;
      margin-left: -15px;
      border: 1px solid #bbbbbb;
      border-radius: 5px 5px;
      padding: 5px 7px; }
      .upload-meta .TextContainer .select-list a {
        cursor: pointer;
        user-select: none;
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        text-decoration: underline;
        color: #243761; }
    .upload-meta .TextContainer .SubmitButton {
      height: 48px;
      width: 150px;
      border-radius: 4px;
      background-color: #243761;
      color: #ffffff;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: bold;
      margin-right: 15px; }
    .upload-meta .TextContainer .secondary-button {
      height: 48px;
      width: 150px;
      border-radius: 4px;
      background-color: lightgray;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: bold;
      margin-right: 15px; }
    .upload-meta .TextContainer .button-group {
      margin-top: 15px; }
    .upload-meta .TextContainer .add-button {
      height: 30px;
      width: 100px;
      border-radius: 4px;
      background-color: #243761;
      color: #ffffff;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      font-weight: bold; }
    .upload-meta .TextContainer .image-edit {
      background-color: transparent;
      border: none;
      cursor: pointer; }
    .upload-meta .TextContainer .list-buttons button {
      min-width: 75px;
      margin-bottom: 10px; }
    .upload-meta .TextContainer #image-instructions {
      font-family: "Roboto", sans-serif;
      font-size: 10pt;
      font-style: italic;
      margin-left: 5px; }
    .upload-meta .TextContainer #copyright {
      width: 65px; }
    .upload-meta .TextContainer #numberOfPages {
      width: 152px; }
    .upload-meta .TextContainer #seriesNumber {
      width: 65px; }
    .upload-meta .TextContainer #edition {
      width: 145px; }
    .upload-meta .TextContainer #formatList {
      width: 350px;
      color: red; }
