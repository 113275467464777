.journal-issues-detail {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 10px; }
  .journal-issues-detail .title-section .journal-issues-text-container {
    padding-bottom: 15px;
    width: 100%; }
    .journal-issues-detail .title-section .journal-issues-text-container .journal-issues-text {
      font-size: 42px;
      font-weight: bold;
      line-height: 28px;
      color: #243761;
      margin-left: -20px; }
  .journal-issues-detail .form-control-small {
    height: 30px; }
  .journal-issues-detail .col-form-label {
    margin-top: 4px;
    margin-bottom: 0; }
  .journal-issues-detail .form-check-group {
    margin-top: 4px; }
  .journal-issues-detail .display-only {
    cursor: default; }
  .journal-issues-detail .TextContainer {
    min-height: calc(90vh - 86px);
    display: flex;
    flex-direction: column;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    padding-top: 30px;
    padding-bottom: 50px; }
    .journal-issues-detail .TextContainer .required {
      width: 100%; }
      .journal-issues-detail .TextContainer .required .required-legend {
        margin-left: -10px; }
    .journal-issues-detail .TextContainer .DetailsSection {
      display: flex;
      justify-content: center;
      align-items: center; }
      .journal-issues-detail .TextContainer .DetailsSection .DetailsContainer {
        width: 95%;
        color: #4a4a4a;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        line-height: 32px; }
        .journal-issues-detail .TextContainer .DetailsSection .DetailsContainer #sub-title {
          margin-top: 10px; }
    .journal-issues-detail .TextContainer .row {
      margin: 0;
      margin-bottom: 20px;
      color: #4a4a4a;
      font-family: "Roboto", sans-serif;
      font-size: 15px; }
    .journal-issues-detail .TextContainer .row:first-of-type {
      margin-top: 10px; }
    .journal-issues-detail .TextContainer .select-title {
      margin-left: 15px;
      top: 10px; }
      .journal-issues-detail .TextContainer .select-title .select-title-text {
        position: relative;
        top: 12px; }
    .journal-issues-detail .TextContainer .select-list {
      margin-top: -5px;
      margin-bottom: 25px;
      margin-left: -15px;
      border: 1px solid #bbbbbb;
      border-radius: 5px 5px;
      padding: 5px 7px; }
      .journal-issues-detail .TextContainer .select-list a {
        cursor: pointer;
        user-select: none;
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        text-decoration: underline;
        color: #243761; }
    .journal-issues-detail .TextContainer .SubmitButton {
      height: 48px;
      width: 150px;
      border-radius: 4px;
      background-color: #243761;
      color: #ffffff;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: bold;
      margin-right: 15px; }
    .journal-issues-detail .TextContainer .secondary-button {
      height: 48px;
      width: 150px;
      border-radius: 4px;
      background-color: lightgray;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: bold;
      margin-right: 15px; }
    .journal-issues-detail .TextContainer .button-group {
      margin-top: 15px; }
    .journal-issues-detail .TextContainer .add-button {
      height: 30px;
      width: 100px;
      border-radius: 4px;
      background-color: #243761;
      color: #ffffff;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      font-weight: bold; }
    .journal-issues-detail .TextContainer .image-edit {
      background-color: transparent;
      border: none;
      cursor: pointer; }
    .journal-issues-detail .TextContainer .list-buttons button {
      min-width: 75px;
      margin-bottom: 10px; }
  .journal-issues-detail .notification-group {
    margin-bottom: 10px !important; }
  .journal-issues-detail #year, .journal-issues-detail #volume, .journal-issues-detail #issue {
    width: 70px; }
  .journal-issues-detail #journal {
    min-width: 300px; }
  .journal-issues-detail #articles {
    margin-left: -15px; }
  .journal-issues-detail #url {
    width: 420px; }

.react-tip {
  color: red; }
