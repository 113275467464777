.book-modal-main {
  position: fixed;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .book-modal-main h2 {
    text-align: center; }

.BookReviewCustomModal {
  height: 300px !important;
  width: 600px !important;
  border: 3px solid black;
  border-radius: 4px;
  background-color: white;
  padding: 0 20px 20px 20px; }
  .BookReviewCustomModal #avatar {
    display: none; }
  .BookReviewCustomModal #fileUploadGroup {
    margin-top: 15px; }
  .BookReviewCustomModal #reviewPDFName {
    margin-left: 10px;
    width: 475px; }
  .BookReviewCustomModal .fieldGroup {
    margin: 15px 0; }
  .BookReviewCustomModal .fieldGroup select {
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid lightgray; }
  .BookReviewCustomModal .dialogButtons {
    margin-top: 45px; }

.format-type-group {
  margin-left: 15px; }
  .format-type-group label {
    margin-right: 25px; }
    .format-type-group label input {
      margin-right: 5px; }
