.session-modal-main {
  position: fixed;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .session-modal-main h2 {
    text-align: center; }

.SessionTimeoutCustomModal {
  height: 340px !important;
  width: 600px !important;
  border: 3px solid black;
  border-radius: 4px;
  background-color: white;
  padding: 20px 20px 20px 20px; }
  .SessionTimeoutCustomModal .fieldContainer {
    font-family: 'Roboto', Helvetica, Arial, verdana, sans-serif;
    font-size: 18px;
    margin-top: 35px;
    margin-bottom: 55px;
    text-align: center; }
