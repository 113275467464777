.select-title {
  top: 10px; }
  .select-title .select-title-text {
    position: relative;
    top: 12px; }
  .select-title #contributor-button {
    margin-left: 40px; }

.select-list {
  margin-top: -5px;
  margin-bottom: 25px;
  margin-left: -15px;
  border: 1px solid #bbbbbb;
  border-radius: 5px 5px;
  padding: 5px 7px; }
  .select-list a {
    cursor: pointer;
    user-select: none;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    text-decoration: underline;
    color: #243761; }
