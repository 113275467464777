.SearchResultsList {
  text-align: left; }
  .SearchResultsList .PerPageSelect {
    width: 20%;
    float: right; }
  .SearchResultsList .SearchResultItemSection:nth-child(even) {
    border-top: solid #dbd5d5 1px;
    border-bottom: solid #dbd5d5 1px; }
  .SearchResultsList .SearchResultItemSection:last-child {
    border-bottom: none; }
  .SearchResultsList .SearchResultItemSection:hover {
    background: #e6f0ff; }
  .SearchResultsList .SearchResultItemSection {
    height: 212px;
    display: flex;
    padding-top: 23px;
    padding-bottom: 23px; }
    .SearchResultsList .SearchResultItemSection .SearchResultItemImageContainer {
      flex: 1; }
    .SearchResultsList .SearchResultItemSection .SearchResultItemImage {
      width: 120px;
      height: 163px; }
    .SearchResultsList .SearchResultItemSection .SearchResultItemDetailsContainer {
      flex: 5.5;
      padding: 10px; }
      .SearchResultsList .SearchResultItemSection .SearchResultItemDetailsContainer .SearchResultItemTitleContainer {
        color: #4a4a4a;
        font-family: Roboto, sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px; }
      .SearchResultsList .SearchResultItemSection .SearchResultItemDetailsContainer .SearchResultItemAuthorContainer {
        color: #4a4a4a;
        font-family: Roboto, sans-serif;
        font-size: 16px;
        line-height: 19px; }
      .SearchResultsList .SearchResultItemSection .SearchResultItemDetailsContainer .SearchResultItemPublicationDateContainer {
        color: #4a4a4a;
        font-family: Roboto, sans-serif;
        font-size: 16px;
        line-height: 19px; }
      .SearchResultsList .SearchResultItemSection .SearchResultItemDetailsContainer .SearchResultItemNumReviewsContainer {
        color: #4a4a4a;
        font-family: Roboto, sans-serif;
        font-size: 16px;
        line-height: 19px; }

a, a:hover, a:visited {
  text-decoration: none; }
