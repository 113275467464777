.journal-notifications-page {
  width: 100%;
  text-align: left;
  min-height: calc(100vh - 150px); }
  .journal-notifications-page strong {
    font-weight: 500; }
  .journal-notifications-page .notifications-text-container {
    padding-bottom: 15px;
    width: 100%;
    text-align: center; }
    .journal-notifications-page .notifications-text-container .notifications-text {
      font-size: 42px;
      font-weight: bold;
      line-height: 28px;
      color: #243761;
      z-index: 99;
      position: relative; }
  .journal-notifications-page .journal-notifications-description {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
    text-align: center; }
    .journal-notifications-page .journal-notifications-description label {
      line-height: 28px;
      color: #243761;
      font-size: 16px;
      width: 800px !important;
      font-weight: normal; }
  .journal-notifications-page .notifications-area {
    font-family: 'Roboto', Helvetica, Arial, verdana, sans-serif;
    position: relative;
    width: 1000px;
    margin: 0 auto;
    margin-top: 15px;
    text-align: left; }
    .journal-notifications-page .notifications-area .journal-publisher {
      font-weight: normal;
      font-size: 20px; }
    .journal-notifications-page .notifications-area .journal-description {
      font-weight: normal;
      margin-bottom: 15px;
      font-size: 16px; }
  .journal-notifications-page .standard-select {
    font-family: 'Roboto', Helvetica, Arial, verdana, sans-serif; }
  .journal-notifications-page .SubmitButton {
    height: 48px;
    width: 150px;
    border-radius: 4px;
    background-color: #243761;
    color: #ffffff;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: bold;
    margin-right: 15px; }
  .journal-notifications-page .secondary-button {
    height: 48px;
    width: 150px;
    border-radius: 4px;
    background-color: lightgray;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: bold;
    margin-right: 15px; }
  .journal-notifications-page .button-group {
    margin-top: 15px;
    margin-bottom: 45px; }
  .journal-notifications-page .journal-content-container {
    width: 100%;
    position: relative;
    display: flex; }
  .journal-notifications-page .journal-content-left {
    width: 15%;
    display: flex;
    padding-right: 15px; }
    .journal-notifications-page .journal-content-left .journal-image {
      width: 125px;
      height: 184px; }
  .journal-notifications-page .journal-content-left img {
    width: 100%; }
  .journal-notifications-page .journal-content-right {
    margin-left: 15px;
    width: 85%; }
    .journal-notifications-page .journal-content-right p {
      margin-bottom: 10px; }
  .journal-notifications-page .journal-frequency {
    margin-bottom: 0px;
    font-size: 16px; }
  .journal-notifications-page .journal-issn {
    font-size: 16px; }
  .journal-notifications-page .ml-20 {
    margin-left: 20px; }
  .journal-notifications-page .journal-content-right a {
    color: #333; }
  .journal-notifications-page .journal-issn-content {
    font-weight: normal; }
  .journal-notifications-page #title {
    font-size: 20px; }
