.book-modal-main {
  position: fixed;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .book-modal-main h2 {
    text-align: center; }
  .book-modal-main #isbn, .book-modal-main #price {
    width: 145px; }

.BookFormatCustomModal {
  width: 600px !important;
  border: 3px solid black;
  border-radius: 4px;
  background-color: white;
  padding: 0 20px 20px 20px; }

.fieldGroup {
  margin: 10px 0; }

.fieldGroup select {
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid lightgray; }

.format-type-group {
  margin-left: 15px; }
  .format-type-group label {
    margin-right: 25px; }
    .format-type-group label input {
      margin-right: 5px; }
