.AdminFilterBar {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px;
  overflow: hidden; }
  .AdminFilterBar .FilterTitle {
    max-height: 50px;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #4a4a4a;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 21px;
    border-bottom: solid #c9c8c8 1px;
    padding-bottom: 15px;
    padding-top: 15px; }
  .AdminFilterBar .PublicationDateSection {
    flex: 2.8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: #4a4a4a;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    border-bottom: solid #c9c8c8 1px; }
    .AdminFilterBar .PublicationDateSection .PublicationDateTitle {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center; }
    .AdminFilterBar .PublicationDateSection .PublicationDateContainer {
      flex: 4;
      width: 100%; }
  .AdminFilterBar .ContentSection {
    flex: 2.3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: #4a4a4a;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    border-bottom: solid #c9c8c8 1px; }
    .AdminFilterBar .ContentSection .ContentSectionTitle {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center; }
    .AdminFilterBar .ContentSection .ContentSectionContainer {
      flex: 3;
      width: 100%; }
  .AdminFilterBar .MeetingsSection {
    flex: 1.8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: #4a4a4a;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    border-bottom: solid #c9c8c8 1px; }
    .AdminFilterBar .MeetingsSection .MeetingsSectionTitle {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center; }
    .AdminFilterBar .MeetingsSection .MeetingsSectionContainer {
      flex: 2;
      width: 100%; }
  .AdminFilterBar .ReviewsSection {
    flex: 1.8;
    max-height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: #4a4a4a;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    border-bottom: solid #c9c8c8 1px;
    overflow: hidden;
    min-width: 230px; }
    .AdminFilterBar .ReviewsSection .ReviewsSectionTitle {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center; }
    .AdminFilterBar .ReviewsSection .ReviewsSectionContainer {
      flex: 2;
      width: 100%; }
  .AdminFilterBar .PublisherSection {
    flex: 4.2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: #4a4a4a;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px; }
    .AdminFilterBar .PublisherSection .PublisherSectionTitle {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center; }
    .AdminFilterBar .PublisherSection .PublisherSectionContainer {
      flex: 5;
      width: 100%; }
  .AdminFilterBar .ViewMore {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 300;
    line-height: 14px;
    text-decoration: underline;
    cursor: pointer; }
  .AdminFilterBar .PadTop10 {
    padding-top: 10px; }
  .AdminFilterBar .PadBottom10 {
    padding-bottom: 10px; }
  .AdminFilterBar .ShowText {
    display: inherit; }
  .AdminFilterBar .HideText {
    display: none; }
  .AdminFilterBar label {
    font-weight: normal;
    margin-left: 15px;
    color: #4a4a4a;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 16px; }
  .AdminFilterBar #journalCheckbox {
    display: inherit; }
