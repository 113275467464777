.notifications-page {
  width: 100%;
  text-align: left;
  min-height: calc(100vh - 150px); }
  .notifications-page .notifications-text-container {
    padding-bottom: 15px;
    width: 100%;
    text-align: center; }
    .notifications-page .notifications-text-container .notifications-text {
      font-size: 42px;
      font-weight: bold;
      line-height: 28px;
      color: #243761; }
  .notifications-page .notifications-description {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
    text-align: center; }
    .notifications-page .notifications-description label {
      line-height: 28px;
      color: #243761;
      font-size: 16px;
      width: 800px !important;
      font-weight: normal; }
  .notifications-page .notifications-area {
    position: relative;
    width: 900px;
    display: table;
    margin: 0 auto; }
    .notifications-page .notifications-area div {
      padding: 5px; }
    .notifications-page .notifications-area label {
      margin-right: 15px; }
      .notifications-page .notifications-area label input {
        margin-right: 5px; }
    .notifications-page .notifications-area #categories {
      margin-left: 10px; }
    .notifications-page .notifications-area #methodsAndTexts {
      margin-left: 5px; }
  .notifications-page .standard-select {
    font-family: 'Roboto', Helvetica, Arial, verdana, sans-serif; }
  .notifications-page .SubmitButton {
    height: 48px;
    width: 150px;
    border-radius: 4px;
    background-color: #243761;
    color: #ffffff;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: bold;
    margin-right: 15px; }
  .notifications-page .secondary-button {
    height: 48px;
    width: 150px;
    border-radius: 4px;
    background-color: lightgray;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: bold;
    margin-right: 15px; }
  .notifications-page .button-group {
    margin-top: 15px;
    margin-bottom: 45px; }

.notification-test {
  width: 100%;
  margin-top: 25px;
  text-align: center;
  padding: 25px; }
  .notification-test .button-section {
    margin-top: 40px;
    font-size: 12pt; }
  .notification-test .recipient {
    margin-top: 25px; }
  .notification-test .reset-button-section {
    margin-top: 40px;
    font-size: 10pt; }
