.adminMain .SearchAndTitleContainer {
  margin-top: 65px; }
  .adminMain .SearchAndTitleContainer .Search {
    flex: 1; }

.adminMenuPage {
  width: 100%;
  height: 400px;
  text-align: center;
  padding-top: 15px; }
  .adminMenuPage .title {
    margin: 55px auto 0;
    font-size: 22pt;
    font-weight: bold;
    cursor: default;
    width: 400px; }
  .adminMenuPage .body {
    margin-top: 15px;
    font-size: 12pt;
    font-weight: normal;
    text-align: center; }
    .adminMenuPage .body .systemContentMenu {
      width: 400px;
      margin: auto; }
      .adminMenuPage .body .systemContentMenu ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        font-size: 14px;
        color: #717171;
        width: 100%;
        cursor: pointer; }
        .adminMenuPage .body .systemContentMenu ul li {
          padding: 7px 10px; }
          .adminMenuPage .body .systemContentMenu ul li a:link {
            color: #337ab7;
            text-decoration: none; }
          .adminMenuPage .body .systemContentMenu ul li a:hover {
            color: #337ab7; }
          .adminMenuPage .body .systemContentMenu ul li a:visited {
            color: #337ab7; }
