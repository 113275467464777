.AboutPage .BackgroundImageSection {
  height: calc(30vh - 75px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../../Assets/Images/EmoryArchive.JPG");
  background-repeat: no-repeat;
  background-size: cover;
  border-top: solid black 2px;
  border-bottom: solid black 2px;
  background-position: 100% center;
  background-attachment: fixed; }

.AboutPage .SearchAndTitleContainer .Search {
  flex: 1; }

.AboutPage .TextContainer {
  min-height: calc(60vh - 75px);
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  font-size: 16px;
  line-height: 32px;
  padding-top: 60px;
  padding-bottom: 50px; }
  .AboutPage .TextContainer .AboutUsTextContainer .AboutUsText {
    font-size: 42px;
    font-weight: bold;
    line-height: 28px;
    color: #243761; }
  .AboutPage .TextContainer .row {
    margin: 0;
    margin-bottom: 20px;
    color: #4a4a4a;
    font-family: Roboto;
    font-size: 16px;
    line-height: 32px; }
  .AboutPage .TextContainer .row:first-of-type {
    margin-top: 10px; }

@media (min-width: 1200px) {
  .AboutPage .container {
    width: 970px !important; } }

@media (min-width: 992px) {
  .AboutPage .container {
    width: 970px !important; } }
