#admin-quick-filters {
  position: relative;
  left: 50%;
  text-align: center;
  transform: translatex(-48%);
  width: 800px; }
  #admin-quick-filters h2 {
    font-size: 16pt; }
  #admin-quick-filters ul {
    list-style-type: none;
    padding: 0; }
  #admin-quick-filters li {
    margin: 10px; }
  #admin-quick-filters a:link {
    color: #337ab7; }
  #admin-quick-filters a:visited {
    color: #337ab7; }
  #admin-quick-filters .Table {
    display: table; }
  #admin-quick-filters .Row {
    display: table-row;
    text-align: center; }
  #admin-quick-filters .Cell {
    display: table-cell;
    min-width: 250px;
    margin: 0;
    padding: 0; }
