.book-export-history-main {
  position: fixed;
  max-width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  user-select: none; }
  .book-export-history-main h2 {
    text-align: center; }
  .book-export-history-main .export-history-table {
    height: 475px;
    overflow-y: scroll;
    border: 1px solid darkgray; }
  .book-export-history-main #dialog-buttons {
    margin-top: 10px;
    text-align: center; }
  .book-export-history-main table {
    position: relative;
    border-collapse: collapse;
    width: 100%; }
  .book-export-history-main th {
    background-color: #dedede;
    position: sticky;
    top: 0;
    padding: 5px;
    text-align: center; }
  .book-export-history-main td {
    border: 1px solid darkgray;
    padding: 5px; }

.BookExportHistoryCustomModal {
  height: 600px !important;
  width: 800px !important;
  border: 3px solid black;
  border-radius: 4px;
  background-color: white;
  padding: 0 20px 20px 20px; }

.status-type-group {
  margin-left: 15px; }
  .status-type-group label {
    margin-right: 25px;
    font-weight: normal; }
    .status-type-group label input {
      margin-right: 5px; }
