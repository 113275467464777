.BookDetailsPage {
  min-height: calc(100vh - 170px);
  margin-bottom: 20px; }
  .BookDetailsPage .BackgroundImageSection {
    height: calc(30vh - 75px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: solid black 2px;
    border-bottom: solid black 2px; }
  .BookDetailsPage .BackgroundEmoryImage {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../../Assets/Images/EmoryArchive.JPG");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% center;
    background-attachment: fixed; }
  .BookDetailsPage .BackgroundNoImage {
    background: none; }
  .BookDetailsPage .SearchAndTitleContainer .Search {
    flex: 1; }
  .BookDetailsPage .ContentSection {
    min-height: calc(60vh - 75px); }
    .BookDetailsPage .ContentSection .ContentContainer {
      height: 100%; }
      .BookDetailsPage .ContentSection .ContentContainer .BackButtonContainer {
        height: 60px;
        display: flex;
        align-items: center;
        margin-left: -15px; }
        .BookDetailsPage .ContentSection .ContentContainer .BackButtonContainer .BackButton {
          height: 48px;
          width: 162px;
          border-radius: 4px;
          background-color: #243761;
          color: #ffffff;
          font-family: "Helvetica Neue";
          font-size: 18px;
          font-weight: 400;
          line-height: 15px; }
        .BookDetailsPage .ContentSection .ContentContainer .BackButtonContainer .SaveMessage {
          margin-left: 20px; }
      .BookDetailsPage .ContentSection .ContentContainer .ImageAndDetailsRow {
        min-height: 75%; }
        .BookDetailsPage .ContentSection .ContentContainer .ImageAndDetailsRow .ImageSection {
          min-height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0; }
          .BookDetailsPage .ContentSection .ContentContainer .ImageAndDetailsRow .ImageSection .ConferencePaperImageContainer {
            height: 75%;
            width: 75%;
            background-repeat: no-repeat;
            background-size: cover; }
            .BookDetailsPage .ContentSection .ContentContainer .ImageAndDetailsRow .ImageSection .ConferencePaperImageContainer #BookImage {
              width: 100%; }
        .BookDetailsPage .ContentSection .ContentContainer .ImageAndDetailsRow .DetailsSection {
          min-height: 100%;
          display: flex;
          justify-content: center;
          align-items: center; }
          .BookDetailsPage .ContentSection .ContentContainer .ImageAndDetailsRow .DetailsSection .DetailsContainer {
            min-height: 85%;
            width: 95%;
            color: #4a4a4a;
            font-family: Roboto;
            font-size: 16px;
            line-height: 32px; }
  .BookDetailsPage .SignInButton {
    height: 48px;
    width: 150px;
    border-radius: 4px;
    background-color: #243761;
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    line-height: 15px; }
  @media (min-width: 1200px) {
    .BookDetailsPage .container {
      width: 970px !important; } }
