.select-field-control {
  font-family: "Roboto", sans-serif;
  font-size: 15px; }
  .select-field-control .select-field-title {
    margin-right: 10px; }
  .select-field-control .select-field {
    font-family: "Roboto", sans-serif;
    min-width: 50px;
    padding: 3px;
    border: 1px solid lightgray;
    border-radius: 4px 4px; }
