.notification-test {
  font-family: "Roboto", Helvetica, sans-serif;
  font-size: 16px;
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: calc(100vh - 185px);
  text-align: left;
  width: 100%; }
  .notification-test .notification-content {
    width: 1000px !important;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    color: #243761; }
    .notification-test .notification-content .notification-title {
      text-align: center; }
      .notification-test .notification-content .notification-title h2 {
        font-size: 42px;
        font-weight: bold;
        line-height: 28px;
        color: #243761;
        margin-bottom: 35px; }
    .notification-test .notification-content .advanced-options {
      margin-top: 55px; }
      .notification-test .notification-content .advanced-options .advanced-title {
        font-weight: bold;
        font-size: 18px; }
  .notification-test .button-section {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 16px; }
  .notification-test .journal-notification-desc {
    text-align: left; }
  .notification-test .journal-select-section {
    margin-top: 15px; }
  .notification-test hr {
    border: none;
    height: 2px;
    background: black; }
  .notification-test .qa-button {
    margin-top: 25px;
    max-width: 800px; }
    .notification-test .qa-button button {
      margin-top: 5px; }
