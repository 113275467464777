.RBLPage {
  font-family: 'Roboto', Helvetica, Arial, sans-serif; }
  .RBLPage .BackgroundImageSection {
    height: calc(30vh - 75px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../../Assets/Images/EmoryArchive.JPG");
    background-repeat: no-repeat;
    background-size: cover;
    border-top: solid black 2px;
    border-bottom: solid black 2px;
    background-position: 100% center;
    background-attachment: fixed; }
  .RBLPage .SearchAndTitleContainer .Search {
    flex: 1; }
  .RBLPage .ContentSection {
    min-height: calc(60vh - 75px); }
    .RBLPage .ContentSection .LeftSection {
      display: flex;
      justify-content: center;
      height: 100%; }
      .RBLPage .ContentSection .LeftSection .LeftContainer {
        display: flex;
        flex-direction: column;
        width: 70%; }
        .RBLPage .ContentSection .LeftSection .LeftContainer .RBLText {
          flex: 1;
          margin-top: 45px; }
          .RBLPage .ContentSection .LeftSection .LeftContainer .RBLText .RBLTextTitle {
            color: #2f4063; }
          .RBLPage .ContentSection .LeftSection .LeftContainer .RBLText .RBLTextDetails {
            font-family: Roboto;
            font-size: 16px;
            line-height: 32px;
            max-width: 700px;
            margin-bottom: 15px; }
            .RBLPage .ContentSection .LeftSection .LeftContainer .RBLText .RBLTextDetails .rblBulletList {
              margin-left: 45px; }
              .RBLPage .ContentSection .LeftSection .LeftContainer .RBLText .RBLTextDetails .rblBulletList li {
                list-style-type: disc;
                padding-left: 15px; }
        .RBLPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection {
          flex: 3;
          height: 100%; }
          .RBLPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection .row {
            height: 135px;
            margin: 0; }
          .RBLPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection .col-xs-4 {
            height: 100%;
            padding: 0;
            display: flex;
            justify-content: flex-start;
            align-items: center; }
          .RBLPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection .pad22 {
            padding: 22px; }
          .RBLPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection .ATLA {
            width: 145px;
            height: 55px; }
          .RBLPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection .Baker {
            width: 125px;
            height: 30px; }
          .RBLPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection .BaylorPress {
            width: 100px;
            height: 65px; }
          .RBLPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection .Bloomsbury {
            height: 100px;
            width: 140px; }
          .RBLPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection .Brill {
            width: 65px;
            height: 95px; }
          .RBLPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection .DG {
            width: 112px;
            height: 55px; }
          .RBLPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection .Mohr {
            width: 125px;
            height: 38px; }
          .RBLPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection .PSUP {
            width: 135px;
            height: 50px; }
          .RBLPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection .WS {
            width: 135px;
            height: 16px; }
          .RBLPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection .WJK {
            width: 100px;
            height: 68px; }
      .RBLPage .ContentSection .LeftSection .RBLTextBottom {
        text-align: left;
        margin-bottom: 20px;
        font-family: Roboto;
        font-size: 16px;
        line-height: 32px; }
    .RBLPage .ContentSection .RightSection .rblLogo-section {
      width: 350px;
      margin-top: 65px;
      text-align: center; }
      .RBLPage .ContentSection .RightSection .rblLogo-section .rblLogo {
        margin-left: 110px;
        width: 130px;
        margin-bottom: 25px; }
    .RBLPage .ContentSection .RightSection .editorialTitle {
      width: 350px;
      font-size: 20px;
      text-align: center;
      font-weight: bold;
      margin-bottom: 10px; }
    .RBLPage .ContentSection .RightSection .RBLList {
      font-family: 'Roboto', Helvetica, Arial, sans-serif;
      font-size: 16px;
      width: 400px; }
  .RBLPage ul {
    list-style-type: none;
    padding: 0;
    margin: 0; }
    .RBLPage ul li {
      margin-bottom: 10px; }
  @media (min-width: 1200px) {
    .RBLPage .container {
      width: 970px !important; } }
  @media (min-width: 992px) {
    .RBLPage .container {
      width: 970px !important; } }
