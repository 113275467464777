.subject-selections-snapshot {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: calc(100vh - 160px);
  text-align: center; }
  .subject-selections-snapshot .download-table-xls-button {
    visibility: hidden; }
  .subject-selections-snapshot .title-section .rbl-export-container {
    text-align: center;
    width: 100%;
    margin-bottom: 15px; }
    .subject-selections-snapshot .title-section .rbl-export-container .title-text {
      font-size: 42px;
      font-weight: bold;
      line-height: 28px;
      color: #243761; }
    .subject-selections-snapshot .title-section .rbl-export-container .title-subtext {
      font-weight: bold;
      color: #243761; }
  .subject-selections-snapshot .TextContainer {
    display: flex;
    flex-direction: column;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    padding-top: 30px; }
    .subject-selections-snapshot .TextContainer .row {
      margin: 0;
      color: #4a4a4a;
      font-family: "Roboto", sans-serif;
      font-size: 15px; }
    .subject-selections-snapshot .TextContainer .button-section {
      margin-top: 50px; }
    .subject-selections-snapshot .TextContainer .export-content {
      text-align: left; }
      .subject-selections-snapshot .TextContainer .export-content .RBLExportLink {
        margin-bottom: 15px;
        margin-left: 15px; }
      .subject-selections-snapshot .TextContainer .export-content .SubmitButton {
        height: 48px;
        width: 150px;
        border-radius: 4px;
        background-color: #243761;
        color: #ffffff;
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-weight: bold; }
      .subject-selections-snapshot .TextContainer .export-content .secondary-button {
        height: 48px;
        width: 150px;
        border-radius: 4px;
        background-color: lightgray;
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-weight: bold;
        margin-right: 15px; }
      .subject-selections-snapshot .TextContainer .export-content .export-history-link {
        cursor: pointer; }
  .subject-selections-snapshot .date-selection {
    background: #f0f0f0;
    font-size: 10px;
    width: 225px;
    height: 210px; }
  .subject-selections-snapshot .data-display {
    width: 100%;
    padding: 0 125px 20px 125px;
    text-align: left; }
    .subject-selections-snapshot .data-display .export-link {
      font-size: 12pt;
      cursor: pointer; }
    .subject-selections-snapshot .data-display .table-head th {
      padding: 5px;
      border: 1px solid darkgray;
      text-align: center; }
    .subject-selections-snapshot .data-display .table-row td {
      padding: 5px;
      border: 1px solid darkgray;
      text-align: center; }

.react-datepicker {
  font-size: 1em; }

.react-datepicker__header {
  padding-top: 0.8em; }

.react-datepicker__month {
  margin: 0.4em 1em; }

.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em; }

.react-datepicker__current-month {
  font-size: 1em; }

.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent; }

.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em; }

.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em; }

.rbl-export-tooltip {
  text-align: justify !important; }
