.book-editor {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 10px; }
  .book-editor .title-section .book-text-container {
    padding-bottom: 15px;
    width: 100%; }
    .book-editor .title-section .book-text-container .book-text {
      font-size: 42px;
      font-weight: bold;
      line-height: 28px;
      color: #243761; }
  .book-editor .title-section .required-legend {
    text-align: center;
    width: 100%; }
  .book-editor .source-file {
    margin-top: -15px !important; }
  .book-editor .source-file-empty {
    margin-top: -35px !important; }
  .book-editor .form-control-small {
    height: 30px; }
  .book-editor .col-form-label {
    margin-top: 4px;
    margin-bottom: 0; }
  .book-editor .form-check-group {
    margin-top: 4px; }
  .book-editor .display-only {
    cursor: default; }
  .book-editor .BackButtonContainer {
    margin: 0 150px 25px 0;
    display: flex;
    align-items: center; }
  .book-editor #BookImage {
    width: 125px;
    height: 184px; }
  .book-editor .TextContainer {
    min-height: calc(60vh - 75px);
    display: flex;
    flex-direction: column;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    padding-top: 30px;
    padding-bottom: 50px; }
    .book-editor .TextContainer .DetailsSection {
      display: flex;
      justify-content: center;
      align-items: center; }
      .book-editor .TextContainer .DetailsSection .DetailsContainer {
        width: 95%;
        color: #4a4a4a;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        line-height: 32px; }
        .book-editor .TextContainer .DetailsSection .DetailsContainer #sub-title {
          margin-top: 10px; }
    .book-editor .TextContainer .row {
      margin: 0;
      margin-bottom: 20px;
      color: #4a4a4a;
      font-family: "Roboto", sans-serif;
      font-size: 15px; }
    .book-editor .TextContainer .row:first-of-type {
      margin-top: 10px; }
    .book-editor .TextContainer .select-title {
      margin-left: 15px;
      top: 10px; }
      .book-editor .TextContainer .select-title .select-title-text {
        position: relative;
        top: 12px; }
    .book-editor .TextContainer .select-list {
      margin-top: -5px;
      margin-bottom: 25px;
      margin-left: -15px;
      border: 1px solid #bbbbbb;
      border-radius: 5px 5px;
      padding: 5px 7px; }
      .book-editor .TextContainer .select-list a {
        cursor: pointer;
        user-select: none;
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        text-decoration: underline;
        color: #243761; }
    .book-editor .TextContainer .SubmitButton {
      height: 48px;
      width: 150px;
      border-radius: 4px;
      background-color: #243761;
      color: #ffffff;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: bold; }
    .book-editor .TextContainer .EditButton {
      height: 30px;
      border-radius: 4px;
      background-color: #243761;
      color: #ffffff;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      font-weight: bold;
      position: relative;
      left: 15px;
      top: -15px; }
    .book-editor .TextContainer .add-button {
      height: 30px;
      width: 100px;
      border-radius: 4px;
      background-color: #243761;
      color: #ffffff;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      font-weight: bold; }
    .book-editor .TextContainer .image-edit {
      background-color: transparent;
      border: none;
      cursor: pointer; }
    .book-editor .TextContainer .list-buttons button {
      min-width: 75px;
      margin-bottom: 10px; }
    .book-editor .TextContainer #image-instructions {
      font-family: "Roboto", sans-serif;
      font-size: 10pt;
      font-style: italic;
      margin-left: 5px; }
    .book-editor .TextContainer #copyright {
      width: 65px; }
    .book-editor .TextContainer #numberOfPages {
      width: 152px; }
    .book-editor .TextContainer #seriesNumber {
      width: 65px; }
    .book-editor .TextContainer #edition {
      width: 145px; }
    .book-editor .TextContainer #formatList {
      width: 350px;
      color: red; }
  .book-editor .account-selector {
    position: relative;
    left: 15px; }
  .book-editor .suppress-check-box-group, .book-editor .volunteer-check-box-group {
    position: relative;
    left: 15px; }
    .book-editor .suppress-check-box-group label, .book-editor .volunteer-check-box-group label {
      font-weight: bold; }
  .book-editor .volunteer-check-box-group {
    top: -10px; }
  .book-editor .noleftpadding {
    padding-left: 0px !important; }
  .book-editor .required {
    text-align: left; }
  .book-editor .requiredMarker {
    color: red;
    font-weight: normal; }
  .book-editor .requiredText {
    color: default;
    font-weight: normal; }
  .book-editor .modal-button {
    margin-left: 125px; }
  .book-editor #imageDisplay input {
    color: rgba(0, 0, 0, 0); }
  .book-editor #avatar {
    display: none; }
  .book-editor #file-button {
    height: 30px;
    border-radius: 4px;
    background-color: #243761;
    color: #ffffff;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-weight: bold; }
  .book-editor #editSubjects {
    position: relative;
    left: 150px; }
  @media (min-width: 1200px) {
    .book-editor .container {
      width: 1000px !important; } }
  @media (min-width: 992px) {
    .book-editor .container {
      width: 1000px !important; } }
