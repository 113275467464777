.SearchContainerAdmin {
  display: flex;
  max-width: 680px;
  width: 100%;
  height: 85px;
  flex-direction: column;
  margin-top: 23px; }
  .SearchContainerAdmin .TopSearchContainer {
    height: 60px;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
    background-color: #6fa5a2;
    display: flex; }
    .SearchContainerAdmin .TopSearchContainer .FilterSearchContainer {
      flex: 1;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center; }
      .SearchContainerAdmin .TopSearchContainer .FilterSearchContainer .FilterSearchText {
        flex: 1;
        text-align: center;
        line-height: 21px;
        font-size: 18px;
        padding-left: 10px; }
      .SearchContainerAdmin .TopSearchContainer .FilterSearchContainer .FilterSearchDropdown {
        flex: 1; }
    .SearchContainerAdmin .TopSearchContainer .SearchBarContainer {
      flex: 8; }
      .SearchContainerAdmin .TopSearchContainer .SearchBarContainer .SearchBarForm {
        height: 100%; }
      .SearchContainerAdmin .TopSearchContainer .SearchBarContainer .SearchBar {
        width: 100%;
        height: 100%;
        padding-left: 10px; }
    .SearchContainerAdmin .TopSearchContainer .SearchIconContainer {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer; }
    .SearchContainerAdmin .TopSearchContainer .SearchIcon {
      height: 35px;
      width: 37px;
      background: url("../../Assets/Images/Search-Icon.svg"); }
  .SearchContainerAdmin .BottomSearchContainer {
    height: 25px;
    width: 100%;
    background-color: transparent;
    color: #ffffff;
    display: flex; }
    .SearchContainerAdmin .BottomSearchContainer .LeftSection {
      flex: 2; }
    .SearchContainerAdmin .BottomSearchContainer .MiddleSection {
      flex: 8;
      display: flex; }
      .SearchContainerAdmin .BottomSearchContainer .MiddleSection .AdvancedSearchText {
        flex: 1;
        text-align: left;
        cursor: pointer;
        display: none; }
      .SearchContainerAdmin .BottomSearchContainer .MiddleSection .BrowseJournalsText {
        flex: 1;
        text-align: right;
        cursor: pointer;
        display: none; }
    .SearchContainerAdmin .BottomSearchContainer .RightSection {
      flex: 1; }

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #b3bdc7;
  font-family: Roboto;
  font-size: 18px;
  line-height: 21px; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: #b3bdc7;
  font-family: Roboto;
  font-size: 18px; }

:-ms-input-placeholder {
  /* IE 10+ */
  color: #b3bdc7;
  font-family: Roboto;
  font-size: 18px;
  line-height: 21px; }

:-moz-placeholder {
  /* Firefox 18- */
  color: #b3bdc7;
  font-family: Roboto;
  font-size: 18px; }
