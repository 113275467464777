.AdminFooter {
  padding: 10px;
  color: white;
  height: 75px;
  background-color: #2f4063;
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 0; }
  .AdminFooter .LeftFooterContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1; }
    .AdminFooter .LeftFooterContainer .CopyrightContainer {
      font-family: Roboto;
      font-size: 14px;
      line-height: 16px;
      font-weight: 300; }
  .AdminFooter .RightFooterContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1; }
    .AdminFooter .RightFooterContainer .FooterTextContainer {
      width: 550px;
      display: flex;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px; }
      .AdminFooter .RightFooterContainer .FooterTextContainer .ReportsLinkContainer {
        flex: .5;
        cursor: pointer;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        text-align: center; }
      .AdminFooter .RightFooterContainer .FooterTextContainer .SystemManagementLinkContainer {
        flex: .75;
        cursor: pointer;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        text-align: center;
        margin-left: -50px; }
      .AdminFooter .RightFooterContainer .FooterTextContainer .AddBookLinkContainer {
        flex: .5;
        cursor: pointer;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        text-align: center;
        margin-left: -50px; }
      .AdminFooter .RightFooterContainer .FooterTextContainer .JournalsContainer {
        flex: .5;
        cursor: pointer;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        text-align: center;
        margin-left: -50px; }
      .AdminFooter .RightFooterContainer .FooterTextContainer a,
      .AdminFooter .RightFooterContainer .FooterTextContainer a:hover,
      .AdminFooter .RightFooterContainer .FooterTextContainer a:visited {
        color: white;
        text-decoration: none; }
