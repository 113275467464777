.SponsorsPage .BackgroundImageSection {
  height: calc(30vh - 75px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../../Assets/Images/EmoryArchive.JPG");
  background-repeat: no-repeat;
  background-size: cover;
  border-top: solid black 2px;
  border-bottom: solid black 2px;
  background-position: 100% center;
  background-attachment: fixed; }

.SponsorsPage .SearchAndTitleContainer .Search {
  flex: 1; }

.SponsorsPage .ContentSection {
  min-height: calc(60vh - 75px); }
  .SponsorsPage .ContentSection .LeftSection {
    display: flex;
    justify-content: center;
    height: 100%; }
    .SponsorsPage .ContentSection .LeftSection .LeftContainer {
      display: flex;
      flex-direction: column;
      width: 70%; }
      .SponsorsPage .ContentSection .LeftSection .LeftContainer .SponsorsText {
        flex: 1;
        margin-top: 45px; }
        .SponsorsPage .ContentSection .LeftSection .LeftContainer .SponsorsText .SponsorsTextTitle {
          color: #2f4063; }
        .SponsorsPage .ContentSection .LeftSection .LeftContainer .SponsorsText .SponsorsTextDetails {
          font-family: Roboto;
          font-size: 16px;
          line-height: 32px; }
      .SponsorsPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection {
        flex: 3;
        height: 100%; }
        .SponsorsPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection .row {
          height: 135px;
          margin: 0; }
        .SponsorsPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection .col-xs-4 {
          height: 100%;
          padding: 0;
          display: flex;
          justify-content: flex-start;
          align-items: center; }
        .SponsorsPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection .pad22 {
          padding: 22px; }
        .SponsorsPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection .ATLA {
          width: 145px;
          height: 55px; }
        .SponsorsPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection .Baker {
          width: 125px;
          height: 30px; }
        .SponsorsPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection .BaylorPress {
          width: 100px;
          height: 65px; }
        .SponsorsPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection .Bloomsbury {
          height: 100px;
          width: 140px; }
        .SponsorsPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection .Brill {
          width: 65px;
          height: 95px; }
        .SponsorsPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection .DG {
          width: 112px;
          height: 55px; }
        .SponsorsPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection .Mohr {
          width: 125px;
          height: 38px; }
        .SponsorsPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection .PSUP {
          width: 135px;
          height: 50px; }
        .SponsorsPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection .WS {
          width: 135px;
          height: 16px; }
        .SponsorsPage .ContentSection .LeftSection .LeftContainer .SponsorImagesSection .WJK {
          width: 100px;
          height: 68px; }
    .SponsorsPage .ContentSection .LeftSection .SponsorsTextBottom {
      text-align: left;
      margin-bottom: 20px;
      font-family: Roboto;
      font-size: 16px;
      line-height: 32px; }
  .SponsorsPage .ContentSection .RightSection .SponsorsList {
    font-family: Roboto;
    font-size: 16px;
    line-height: 32px;
    margin-top: 110px; }

.SponsorsPage ul {
  list-style-type: none;
  padding: 0;
  margin: 0; }

@media (min-width: 1200px) {
  .SponsorsPage .container {
    width: 970px !important; } }

@media (min-width: 992px) {
  .SponsorsPage .container {
    width: 970px !important; } }
