.book-modal-main {
  position: fixed;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .book-modal-main h2 {
    text-align: center; }

.BookExpertiseCustomModal {
  height: 550px !important;
  width: 1100px !important;
  border: 3px solid black;
  border-radius: 4px;
  background-color: white;
  padding: 0 20px 20px 20px; }
  .BookExpertiseCustomModal .fieldGroup {
    margin: 10px 0; }
    .BookExpertiseCustomModal .fieldGroup label {
      margin-right: 15px; }
      .BookExpertiseCustomModal .fieldGroup label input {
        margin-right: 5px; }
  .BookExpertiseCustomModal .fieldGroup select {
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid lightgray; }
  .BookExpertiseCustomModal .standard-select {
    font-family: 'Roboto', Helvetica, Arial, verdana, sans-serif; }

.subjects {
  width: 100%; }
  .subjects .subject-controls {
    display: table;
    max-width: 1050px;
    margin-left: auto;
    margin-right: auto; }
    .subjects .subject-controls .block {
      display: table-cell;
      vertical-align: middle;
      width: 500px; }
      .subjects .subject-controls .block label {
        font-size: 14pt;
        font-weight: bold;
        margin-left: 10px;
        margin-bottom: -15px; }
    .subjects .subject-controls .blockButtons {
      display: table-cell;
      vertical-align: middle;
      width: 75px;
      text-align: center;
      position: relative;
      top: -20px; }
    .subjects .subject-controls .blockTop {
      display: table-cell;
      vertical-align: top;
      padding-top: 40px;
      padding-left: 25px;
      text-align: center;
      width: 75px; }

.subject-arrow {
  font-size: 24pt;
  cursor: pointer; }

.requiredMarker {
  color: red;
  font-weight: normal; }

#subject-display {
  width: 500px; }

#expertise-display {
  margin-top: 15px; }
