.account-manager {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: calc(100vh - 160px); }
  .account-manager .title-section .account-text-container {
    padding-bottom: 15px;
    width: 100%; }
    .account-manager .title-section .account-text-container .account-text {
      font-size: 42px;
      font-weight: bold;
      line-height: 28px;
      color: #243761; }
  .account-manager .title-section .required-legend {
    text-align: center;
    width: 100%; }
  .account-manager .title-section .account-item {
    margin: 5px 0; }
  .account-manager .form-control-small {
    height: 30px; }
  .account-manager .col-form-label {
    margin-top: 4px;
    margin-bottom: 0; }
  .account-manager .form-check-group {
    margin-top: 4px; }
  .account-manager .display-only {
    cursor: default; }
  .account-manager .TextContainer {
    min-height: calc(60vh - 75px);
    display: flex;
    flex-direction: column;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    padding-top: 30px;
    padding-bottom: 50px; }
    .account-manager .TextContainer .DetailsSection {
      display: flex;
      justify-content: center;
      align-items: center; }
      .account-manager .TextContainer .DetailsSection .DetailsContainer {
        width: 95%;
        color: #4a4a4a;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        line-height: 32px; }
        .account-manager .TextContainer .DetailsSection .DetailsContainer #sub-title {
          margin-top: 10px; }
    .account-manager .TextContainer .account-list {
      margin-left: 150px;
      text-align: left;
      margin-top: 15px; }
    .account-manager .TextContainer .row {
      margin: 0;
      margin-bottom: 20px;
      color: #4a4a4a;
      font-family: "Roboto", sans-serif;
      font-size: 15px; }
    .account-manager .TextContainer .row:first-of-type {
      margin-top: 10px; }
    .account-manager .TextContainer .select-title {
      margin-left: 15px;
      top: 10px; }
      .account-manager .TextContainer .select-title .select-title-text {
        position: relative;
        top: 12px; }
    .account-manager .TextContainer .account-item a {
      cursor: pointer;
      user-select: none;
      font-family: "Roboto", sans-serif;
      font-size: 15px;
      text-decoration: underline;
      color: #243761; }
    .account-manager .TextContainer .select-list {
      margin-top: -5px;
      margin-bottom: 25px;
      margin-left: -15px;
      border: 1px solid #bbbbbb;
      border-radius: 5px 5px;
      padding: 5px 7px; }
      .account-manager .TextContainer .select-list a {
        cursor: pointer;
        user-select: none;
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        text-decoration: underline;
        color: #243761; }
    .account-manager .TextContainer .AccountAddButton {
      height: 30px;
      border-radius: 4px;
      background-color: #243761;
      color: #ffffff;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 15px; }
  @media (min-width: 1200px) {
    .account-manager .container {
      width: 1000px !important; } }
  @media (min-width: 992px) {
    .account-manager .container {
      width: 1000px !important; } }
