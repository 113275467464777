.dialogButtons {
  margin-top: 30px;
  text-align: center; }
  .dialogButtons button {
    font-family: "Roboto", sans-serif;
    font-size: 12pt;
    padding: 5px 10px;
    min-width: 100px;
    margin: 0 25px; }
  .dialogButtons .default-button {
    font-weight: bold;
    background-color: #243761;
    color: white; }
  .dialogButtons .secondary-button {
    font-weight: normal;
    background-color: lightgray;
    color: black; }
