.SponsorsSection {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff; }
  .SponsorsSection .SponsorsContainer {
    height: 130px;
    width: 1200px;
    white-space: nowrap;
    overflow-y: hidden; }
    .SponsorsSection .SponsorsContainer .Sponsors {
      height: 130px;
      width: 215px;
      background-color: #6ea6a3;
      margin-right: 12px;
      margin-left: 12px;
      display: inline-block; }
  .SponsorsSection ::-webkit-scrollbar {
    width: 0;
    /* remove scrollbar space */
    height: 0; }
