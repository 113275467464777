.Footer {
  padding: 10px;
  color: white;
  height: 75px;
  background-color: #2f4063;
  display: flex;
  width: 100%; }
  .Footer .LeftFooterContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1; }
    .Footer .LeftFooterContainer .CopyrightContainer {
      color: #ffffff;
      font-family: Roboto;
      font-size: 14px;
      line-height: 16px;
      font-weight: 200; }
  .Footer .RightFooterContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1; }
    .Footer .RightFooterContainer .FooterTextContainer {
      width: 500px;
      display: flex;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px; }
      .Footer .RightFooterContainer .FooterTextContainer .AboutLinkContainer {
        flex: 1;
        cursor: pointer;
        color: #ffffff;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        text-align: center; }
      .Footer .RightFooterContainer .FooterTextContainer .RBLLinkContainer {
        flex: 0.5;
        cursor: pointer;
        color: #ffffff;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        text-align: center; }
      .Footer .RightFooterContainer .FooterTextContainer .PublishersLinkContainer {
        flex: 1.2;
        cursor: pointer;
        color: #ffffff;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        text-align: center; }
      .Footer .RightFooterContainer .FooterTextContainer .SponsorsLinkContainer {
        flex: 1;
        cursor: pointer;
        color: #ffffff;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        text-align: center; }
      .Footer .RightFooterContainer .FooterTextContainer .ContactLinkContainer {
        flex: 0.9;
        cursor: pointer;
        color: #ffffff;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        text-align: center; }
      .Footer .RightFooterContainer .FooterTextContainer a,
      .Footer .RightFooterContainer .FooterTextContainer a:hover,
      .Footer .RightFooterContainer .FooterTextContainer a:visited {
        color: #ffffff;
        text-decoration: none; }
