.account-select {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  min-width: 250px; }
  .account-select .select-field-title {
    margin-right: 10px; }
  .account-select .account-select-main {
    font-weight: bold; }
  .account-select .account-select-imprint {
    font-weight: normal; }
