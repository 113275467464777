.HomePage .BackgroundImageSection {
  height: calc(100vh - 150px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../../Assets/Images/EmoryArchive.JPG");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% center;
  background-attachment: fixed; }
  .HomePage .BackgroundImageSection .SearchAndTitleContainer {
    height: 197px; }
    .HomePage .BackgroundImageSection .SearchAndTitleContainer .Search {
      flex: 1; }
    .HomePage .BackgroundImageSection .SearchAndTitleContainer .AboveSearchText {
      flex: 1;
      color: #ffffff;
      font-family: Avenir, sans-serif;
      font-size: 43px;
      font-weight: 500;
      line-height: 59px;
      text-align: center;
      text-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
      min-height: 60px; }

.HomePage .SponsorsCarouselContainer {
  height: 22vh;
  width: 100%; }
