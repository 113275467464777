.BookStatusFilter {
  flex: 1.8;
  max-height: 275px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #4a4a4a;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  border-bottom: solid #c9c8c8 1px;
  min-width: 230px;
  margin-top: 15px;
  padding-bottom: 25px; }
  .BookStatusFilter .BookStatusFilterTitle {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center; }
  .BookStatusFilter .BookStatusFilterContent {
    flex: 2;
    width: 100%;
    margin-top: 15px; }
    .BookStatusFilter .BookStatusFilterContent .form-check label {
      left: 15px;
      color: #4a4a4a;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      line-height: 16px; }
      .BookStatusFilter .BookStatusFilterContent .form-check label input {
        position: relative;
        left: -15px; }
