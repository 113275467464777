.journal-detail {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 10px; }
  .journal-detail .title-section {
    position: relative;
    top: 15px;
    left: -27px; }
    .journal-detail .title-section .journal-text-container {
      padding-bottom: 15px;
      width: 100%;
      position: relative;
      top: 10px; }
      .journal-detail .title-section .journal-text-container .journal-text {
        font-size: 42px;
        font-weight: bold;
        line-height: 28px;
        color: #243761; }
      .journal-detail .title-section .journal-text-container .required-legend {
        text-align: center;
        width: 95%; }
  .journal-detail .form-control-small {
    height: 30px; }
  .journal-detail .col-form-label {
    margin-top: 4px;
    margin-bottom: 0; }
  .journal-detail .form-check-group {
    margin-top: 4px; }
  .journal-detail .display-only {
    cursor: default; }
  .journal-detail .TextContainer {
    min-height: calc(90vh - 86px);
    display: flex;
    flex-direction: column;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    padding-top: 30px;
    padding-bottom: 50px; }
    .journal-detail .TextContainer .ImageAndDetailsRow {
      display: flex;
      padding-bottom: 25px; }
      .journal-detail .TextContainer .ImageAndDetailsRow .ImageSection {
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0; }
        .journal-detail .TextContainer .ImageAndDetailsRow .ImageSection .JournalImageContainer {
          height: 75%;
          width: 75%;
          background-repeat: no-repeat;
          background-size: cover; }
          .journal-detail .TextContainer .ImageAndDetailsRow .ImageSection .JournalImageContainer #JournalImage {
            width: 125px;
            height: 184px; }
        .journal-detail .TextContainer .ImageAndDetailsRow .ImageSection #imageDisplay {
          width: 135px; }
          .journal-detail .TextContainer .ImageAndDetailsRow .ImageSection #imageDisplay input {
            color: rgba(0, 0, 0, 0); }
      .journal-detail .TextContainer .ImageAndDetailsRow .DetailsSection {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 50px;
        margin-top: 25px; }
        .journal-detail .TextContainer .ImageAndDetailsRow .DetailsSection .DetailsContainer {
          width: 95%;
          color: #4a4a4a;
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          line-height: 32px; }
          .journal-detail .TextContainer .ImageAndDetailsRow .DetailsSection .DetailsContainer #sub-title {
            margin-top: 10px; }
    .journal-detail .TextContainer .row {
      margin: 0;
      margin-bottom: 20px;
      color: #4a4a4a;
      font-family: "Roboto", sans-serif;
      font-size: 15px;
      line-height: 32px; }
    .journal-detail .TextContainer .row:first-of-type {
      margin-top: 10px; }
    .journal-detail .TextContainer .SubmitButton {
      height: 48px;
      width: 150px;
      border-radius: 4px;
      background-color: #243761;
      color: #ffffff;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: bold;
      margin-right: 15px; }
    .journal-detail .TextContainer .secondary-button {
      height: 48px;
      width: 150px;
      border-radius: 4px;
      background-color: lightgray;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: bold;
      margin-right: 15px; }
    .journal-detail .TextContainer .button-group {
      margin-top: 15px; }
    .journal-detail .TextContainer .add-button {
      height: 30px;
      width: 100px;
      border-radius: 4px;
      background-color: #243761;
      color: #ffffff;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      font-weight: bold; }
    .journal-detail .TextContainer .image-edit {
      background-color: transparent;
      border: none;
      cursor: pointer; }
    .journal-detail .TextContainer .list-buttons button {
      min-width: 75px;
      margin-bottom: 10px; }
    .journal-detail .TextContainer #title, .journal-detail .TextContainer #publisher, .journal-detail .TextContainer #url {
      width: 420px; }
    .journal-detail .TextContainer #printISSN, .journal-detail .TextContainer #onlineISSN {
      width: 105px; }
    .journal-detail .TextContainer #publisher-block {
      margin-top: 10px; }
    .journal-detail .TextContainer #issuesPerYear {
      width: 50px; }
    .journal-detail .TextContainer #avatar {
      display: none; }
    .journal-detail .TextContainer #file-button {
      height: 30px;
      border-radius: 4px;
      background-color: #243761;
      color: #ffffff;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 20px; }
    .journal-detail .TextContainer #description {
      width: 540px; }
  .journal-detail .active-check-box-group label {
    font-weight: bold; }
  .journal-detail .active-check-box-group input {
    margin-top: 8px; }
  @media (min-width: 1200px) {
    .journal-detail .container {
      width: 1000px !important; } }
  @media (min-width: 992px) {
    .journal-detail .container {
      width: 1000px !important; } }
