.VolunteerPage .BackgroundImageSection {
  height: calc(30vh - 75px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../../Assets/Images/EmoryArchive.JPG");
  background-repeat: no-repeat;
  background-size: cover;
  border-top: solid black 2px;
  border-bottom: solid black 2px;
  background-position: 100% center;
  background-attachment: fixed; }

.VolunteerPage .submit-group {
  position: relative;
  text-align: center;
  padding-left: 100px; }

.VolunteerPage .SearchAndTitleContainer .Search {
  flex: 1; }

.VolunteerPage .form-control-small {
  height: 30px; }

.VolunteerPage .col-form-label {
  margin-top: 4px;
  margin-bottom: 0; }

.VolunteerPage .form-check-group {
  margin-top: 4px; }

.VolunteerPage .display-only {
  cursor: default; }

.VolunteerPage .TextContainer {
  min-height: calc(60vh - 75px);
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  font-size: 16px;
  padding-top: 60px;
  padding-bottom: 50px; }
  .VolunteerPage .TextContainer .logoContainer {
    text-align: center;
    margin-bottom: 45px; }
    .VolunteerPage .TextContainer .logoContainer .logo-image {
      max-width: 132px;
      margin-top: -10px;
      margin-left: -45px; }
  .VolunteerPage .TextContainer .VolunteerTextContainer {
    padding-bottom: 15px;
    text-align: center;
    margin-left: -40px; }
    .VolunteerPage .TextContainer .VolunteerTextContainer .VolunteerText {
      font-size: 42px;
      font-weight: bold;
      line-height: 28px;
      color: #243761; }
  .VolunteerPage .TextContainer .VolunteerQuestions {
    text-align: center;
    margin-left: -40px;
    padding-bottom: 15px;
    font-size: 16px;
    font-family: 'Roboto', Helvetica, sans-serif; }
  .VolunteerPage .TextContainer .row {
    margin: 0;
    margin-bottom: 20px;
    color: #4a4a4a;
    font-family: 'Roboto', Helvetica, sans-serif;
    font-size: 15px; }
  .VolunteerPage .TextContainer .row:first-of-type {
    margin-top: 10px; }
  .VolunteerPage .TextContainer .SubmitButton {
    height: 48px;
    width: 150px;
    border-radius: 4px;
    background-color: #243761;
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    line-height: 15px; }

.VolunteerPage .noleftpadding {
  padding-left: 0px !important; }

.VolunteerPage .required {
  text-align: center; }

.VolunteerPage .requiredMarker {
  color: red;
  font-weight: normal; }

.VolunteerPage .requiredText {
  color: default;
  font-weight: normal; }

@media (min-width: 1200px) {
  .VolunteerPage .container {
    width: 970px !important; } }

@media (min-width: 992px) {
  .VolunteerPage .container {
    width: 970px !important; } }
