#edit-essay-button-section {
  margin-left: 240px; }

.essay-contributor-block {
  margin-left: 25px;
  margin-bottom: 10px; }

.essay-contributor-block-list {
  font-size: 10pt;
  margin: 0;
  padding: 0; }

.EditButton {
  height: 30px;
  border-radius: 4px;
  background-color: #243761;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: bold;
  position: relative;
  left: 15px;
  top: -15px; }

@media (min-width: 1200px) {
  .container {
    width: 1000px !important; } }

@media (min-width: 992px) {
  .container {
    width: 1000px !important; } }
