.ConferencePaperDetailsPage {
  margin-bottom: 40px; }
  .ConferencePaperDetailsPage .BackgroundImageSection {
    height: calc(30vh - 75px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../../Assets/Images/EmoryArchive.JPG");
    background-repeat: no-repeat;
    background-size: cover;
    border-top: solid black 2px;
    border-bottom: solid black 2px;
    background-position: 100% center;
    background-attachment: fixed; }
  .ConferencePaperDetailsPage .SearchAndTitleContainer .Search {
    flex: 1; }
  .ConferencePaperDetailsPage .ContentSection {
    min-height: calc(60vh - 75px); }
    .ConferencePaperDetailsPage .ContentSection .ContentContainer {
      height: 100%; }
      .ConferencePaperDetailsPage .ContentSection .ContentContainer .BackButtonContainer {
        height: 60px;
        display: flex;
        align-items: center; }
        .ConferencePaperDetailsPage .ContentSection .ContentContainer .BackButtonContainer .BackButton {
          height: 48px;
          width: 162px;
          border-radius: 4px;
          background-color: #243761;
          color: #ffffff;
          font-family: 'Helvetica Neue';
          font-size: 18px;
          font-weight: 400;
          line-height: 15px; }
      .ConferencePaperDetailsPage .ContentSection .ContentContainer .ImageAndDetailsRow {
        min-height: 75%;
        margin: 0; }
        .ConferencePaperDetailsPage .ContentSection .ContentContainer .ImageAndDetailsRow .ImageSection {
          min-height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0; }
          .ConferencePaperDetailsPage .ContentSection .ContentContainer .ImageAndDetailsRow .ImageSection .ConferencePaperImageContainer {
            height: 85%;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover; }
            .ConferencePaperDetailsPage .ContentSection .ContentContainer .ImageAndDetailsRow .ImageSection .ConferencePaperImageContainer #ConferencePaperImage {
              width: 100%; }
        .ConferencePaperDetailsPage .ContentSection .ContentContainer .ImageAndDetailsRow .DetailsSection {
          min-height: 100%;
          display: flex;
          justify-content: center;
          align-items: center; }
          .ConferencePaperDetailsPage .ContentSection .ContentContainer .ImageAndDetailsRow .DetailsSection .DetailsContainer {
            min-height: 85%;
            width: 95%;
            color: #4a4a4a;
            font-family: Roboto;
            font-size: 16px;
            line-height: 32px; }
  @media (min-width: 1200px) {
    .ConferencePaperDetailsPage .container {
      width: 970px !important; } }
