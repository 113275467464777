.rounded-checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* Hide the browser's default checkbox */
.rounded-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ccc;
  border-radius: 5px; }

/* On mouse-over, add a grey background color */
.rounded-checkbox-container:hover input ~ .checkmark {
  background-color: #aaa; }

/* When the checkbox is checked, add a blue background */
.rounded-checkbox-container input:checked ~ .checkmark {
  /*background-color: #2196F3;*/
  background-color: #243761; }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none; }

/* Show the checkmark when checked */
.rounded-checkbox-container input:checked ~ .checkmark:after {
  display: block; }

/* Style the checkmark/indicator */
.rounded-checkbox-container .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }
