.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); }

.display-block {
  display: block; }

.display-none {
  display: none; }

.LoginBackgroundImageSection {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../../Assets/Images/ModalBackground.JPG");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% center; }

.CustomModal {
  height: 325px !important;
  width: 485px !important;
  border-radius: 4px;
  background-color: #6fa5a2 !important; }

.modal-main {
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.flex-container {
  display: inline;
  flex-wrap: nowrap;
  background-color: #6fa5a2; }
  .flex-container .box {
    width: 80%;
    margin: 10%;
    text-align: center; }
    .flex-container .box #close {
      position: relative;
      top: -40px;
      left: 50px; }
    .flex-container .box .login {
      background-color: #ffffff;
      margin: 10px 5px 15px 5px;
      text-align: center;
      border-radius: 4px; }
      .flex-container .box .login .label-text {
        font-weight: normal;
        margin: 7px 5px;
        color: #4a4a4a;
        font-family: Roboto, sans-serif;
        font-size: 14px;
        line-height: 16px;
        text-align: right;
        width: 65px; }
      .flex-container .box .login .label-header {
        margin-top: 15px;
        padding-bottom: 3px;
        border-bottom: solid #c9c8c8 1px;
        width: 250px !important;
        text-align: center;
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 16px; }
      .flex-container .box .login input {
        width: 250px; }
      .flex-container .box .login .SignInButton {
        height: 35px;
        width: 100px;
        border-radius: 4px;
        background-color: #243761;
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
        line-height: 15px;
        margin: 20px; }
  .flex-container .link {
    color: #ffffff !important; }

.error {
  color: red;
  font-weight: bold; }

.close {
  position: absolute;
  right: 10px;
  width: 32px;
  height: 32px;
  opacity: 0.3; }

.close:hover {
  opacity: 1; }

.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 33px;
  width: 2px;
  background-color: #333; }

.close:before {
  transform: rotate(45deg); }

.close:after {
  transform: rotate(-45deg); }
