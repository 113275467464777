.ContactPage .BackgroundImageSection {
  height: calc(30vh - 75px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../../Assets/Images/EmoryArchive.JPG");
  background-repeat: no-repeat;
  background-size: cover;
  border-top: solid black 2px;
  border-bottom: solid black 2px;
  background-position: 100% center;
  background-attachment: fixed; }

.ContactPage .SearchAndTitleContainer .Search {
  flex: 1; }

.ContactPage .ContentContainer {
  display: flex;
  width: 50%;
  margin: 0 auto;
  min-height: 600px; }
  @media only screen and (max-width: 1380px) {
    .ContactPage .ContentContainer {
      width: 65%; } }
  @media only screen and (max-width: 1050px) {
    .ContactPage .ContentContainer {
      width: 80%; } }
  .ContactPage .ContentContainer .TextContainer {
    display: flex;
    min-height: calc(60vh - 75px);
    flex-direction: column;
    font-family: Roboto;
    font-size: 16px;
    line-height: 32px;
    padding-top: 60px;
    padding-bottom: 50px;
    flex: 1; }
    .ContactPage .ContentContainer .TextContainer .ContactUsTextContainer .ContactUsText {
      color: #243761;
      font-family: Roboto;
      font-size: 42px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 25px; }
    .ContactPage .ContentContainer .TextContainer .MailText {
      color: #243761;
      font-family: Roboto;
      font-size: 28px;
      font-weight: 500;
      line-height: 28px;
      margin-top: 20px;
      margin-bottom: 20px; }
    .ContactPage .ContentContainer .TextContainer .TelephoneNumber {
      color: #243761;
      font-family: Roboto;
      font-size: 28px;
      font-weight: 500;
      line-height: 28px;
      margin-top: 20px;
      margin-bottom: 10px; }
  .ContactPage .ContentContainer .ImageContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    margin-top: 55px; }
    .ContactPage .ContentContainer .ImageContainer .Image {
      background-image: url("../../Assets/Images/luce center 2.jpg");
      height: 422px;
      width: 365px;
      background-repeat: no-repeat;
      background-size: 100% 100%; }

.ContactPage .MailDetailsTextContainer .MailAddress {
  color: #243761;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px; }

@media (min-width: 1200px) {
  .ContactPage .container {
    width: 970px !important; } }

@media (min-width: 992px) {
  .ContactPage .container {
    width: 970px !important; } }
